export default {
  apiUrl: import.meta.env.VUE_APP_API_URL,
  apiExternalUrl: import.meta.env.VUE_APP_API_EXTERNAL_URL,
  sentryDsn: import.meta.env.VUE_APP_SENTRY_DSN,
  sentryEnv: import.meta.env.VUE_APP_SENTRY_ENVIRONMENT,
  tagManagerId: import.meta.env.VUE_APP_TAG_MANAGER_ID,
  googleClientId: import.meta.env.VUE_APP_GOOGLE_CLIENT_ID,
  googleRedirectUri: import.meta.env.VUE_APP_GOOGLE_REDIRECT_URI,
  facebookClientId: import.meta.env.VUE_APP_FACEBOOK_CLIENT_ID,
};
